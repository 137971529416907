import PropTypes from 'prop-types';
// @mui
import {styled} from '@mui/material/styles';
import {AppBar, Chip, Grid, IconButton, InputBase, Paper, Toolbar} from '@mui/material';
// utils
import {useContext, useEffect, useState} from 'react';
import {SportsEsports} from '@mui/icons-material';
import {useLocation, useNavigate} from 'react-router-dom';
import {IoSearch} from "react-icons/io5";
import axios from "axios";
import {bgBlur} from '../../../utils/cssStyles';
// components
//
import AccountPopover from './AccountPopover';
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import {ModalContext} from '../../../ModalContext';
import UserContext from "../../../index";

// ----------------------------------------------------------------------

// const NAV_WIDTH = 280;
const NAV_WIDTH = 0;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({theme}) => ({
    ...bgBlur({color: theme.palette.background.default}),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
    zIndex: 0,
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func,
};

export default function Header({onOpenNav}) {
    const { isModalVisible } = useContext(ModalContext);
    const navigate = useNavigate();
    const path = useLocation();

    return (
        <StyledRoot style={{zIndex: isModalVisible ? 0 : 100, backdropFilter: "blur(33px", backgroundBlendMode: "overlay"}}>
            <StyledToolbar>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" mt={1}>
                    <Grid item xs={path.pathname.includes("search") ? 2 : 4}>
                        <IconButton
                            onClick={onOpenNav}
                            sx={{
                                mr: 1,
                                color: 'text.primary',
                                display: {lg: 'none'},
                            }}
                        >
                            <Iconify icon="eva:menu-2-fill"/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={path.pathname.includes("search") ? 8 : 2}>
                        <Logo sx={{
                            height: '45px',
                            width: '100%',
                            marginTop: '5px',
                            display: path.pathname.includes("search") ? "none" : "block"
                        }}/>
                    </Grid>
                    <Grid item xs={path.pathname.includes("search") ? 2 : 4} sx={{textAlign: 'right'}}>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            <Grid item xs={7} sx={{display: path.pathname.includes("search") ? "none" : "block"}}>
                                <Chip
                                    color="secondary"
                                    size="small"
                                    icon={<SportsEsports/>}
                                    label={1250}
                                    onClick={() => navigate('/app/gaming')}
                                    sx={{display: "none"}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledToolbar>
        </StyledRoot>
    );
}
