// ModalContext.js
import React, { useState } from 'react';

const ModalContext = React.createContext(false);

const ModalProvider = ({children}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    return (
        <ModalContext.Provider value={{isModalVisible, setIsModalVisible}}>
            {children}
        </ModalContext.Provider>
    );
}

export {ModalContext, ModalProvider}