import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';

import {useContext, useEffect} from 'react';

// hooks
import {LocalNotifications} from "@capacitor/local-notifications";
import useResponsive from '../../hooks/useResponsive';
// components
// sections
import { LoginForm } from '../../sections/auth/login';
import UserContext from '../../index';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 320,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { baseAPI, appName } = useContext(UserContext);
  const mdUp = useResponsive('up', 'md');
  
  useEffect(() => {
    LocalNotifications.checkPermissions().then((result) => {
      if (result.state !== "granted") {
        LocalNotifications.requestPermissions().then((result) => {
          console.log(result);
        });
      }
    });
    
    const notifs = LocalNotifications.schedule({
      notifications: [
        {
          title: "Weazper",
          body: "N'oubliez pas de consulter les derniers posts !",
          id: 1,
          schedule: {at: new Date(Date.now() + 1000 * 10)},
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: null,
        },
      ],
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> Login | {appName} </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <img src={`${baseAPI}/public/logo`} width={'300px'} style={{ margin: 'auto' }} alt="login" />
            </Box>
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            {!mdUp && (
                <Box sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: "75px" }}>
                  <img src={`${baseAPI}/public/logo`} width={'100px'} style={{ margin: 'auto' }} alt="login" />
                </Box>
            )}

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
