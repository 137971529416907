// React / React Router
import {Navigate, Outlet, useNavigate, Route, Routes} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';

// theme
import { LocalNotifications } from '@capacitor/local-notifications';
import {Capacitor} from "@capacitor/core";
import {Keyboard} from "@capacitor/keyboard";
import {App as Application} from "@capacitor/app";
import {StatusBar, Style} from "@capacitor/status-bar";
import ThemeProvider from './theme';

// components
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

// Pages
import Login from './pages/main/Login';
import Page404 from './pages/main/Page404';
import Home from './pages/main/Home';
import UserContext from './index';
import { ModalProvider } from './ModalContext';
import ProfileLayout from './layouts/profile';
import Deletion from "./pages/main/Deletion";
import Settings from "./pages/main/Settings";

// ----------------------------------------------------------------------

const PrivateRoute = () => {
  const { user } = useContext(UserContext);

  return user.auth ? <Outlet /> : <Navigate to="/login" />;
};
export default function App() {
  const { user, setKeyboard, newPost, setNewPost } = useContext(UserContext);
  const navigate = useNavigate();
  const [postOpen, setPostOpen] = useState(false);
  
  useEffect(() => {

    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({ style:  Style.Light });

      Keyboard.addListener('keyboardWillShow', info => {
        setKeyboard(true);
      });

      Keyboard.addListener('keyboardDidShow', info => {
        setKeyboard(true);
      });


      Keyboard.addListener('keyboardWillHide', () => {
        setKeyboard(false);
      });

      Keyboard.addListener('keyboardDidHide', () => {
        setKeyboard(false);
      });

      Application.addListener('backButton', ({canGoBack}) => {
        if (postOpen) {
          setPostOpen(false);
          setNewPost(false);
        } else if(window.location.pathname === '/app/feed') {
          Application.exitApp();
        } else if (canGoBack) {
          navigate(-1);
        }
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        Keyboard.removeAllListeners();
      }
    }
  }, []);
  
  useEffect(() => {
    setPostOpen(newPost);
  }, [newPost]);

  return (
    <ThemeProvider>
      <ModalProvider>
        <Routes>
          <Route exact path="/app" element={<PrivateRoute />}>
            <Route exact path="/app" element={<DashboardLayout />}>
              <Route element={<Navigate to="/app/feed" />} index />
              <Route exact path="feed" element={<Home />} />
              <Route exact path="settings" element={<Settings />} />
            </Route>
            <Route exact path="profile" element={<ProfileLayout />} >
              <Route element={<Navigate to={`/app/profile/${user.uuid}`} />} index />
            </Route>
          </Route>
          <Route exact path="login" element={<Login />} />
          <Route exact path="deletion" element={<Deletion />} />
          <Route element={<SimpleLayout />}>
            <Route element={<Navigate to="/app/feed" />} index />
            <Route exact path="404" element={<Page404 />} />
            <Route exact path="*" element={<Navigate to="/404" />} />
          </Route>
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </ModalProvider>
    </ThemeProvider>
  );
}
