import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Box, Button, Container, Typography} from '@mui/material';
import {useContext} from 'react';
import UserContext from '../../index';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  textAlign: 'center'
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const { appName } = useContext(UserContext);

  return (
    <>
      <Helmet>
        <title> 404 Page introuvable | {appName} </title>
      </Helmet>

      <Container style={{textAlign: "center"}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Désolé, la page que vous essayez d'atteindre est introuvable
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Peut-être est-ce une erreur dans l'URL ? Vérifiez si elle ne contient pas d'erreur ou de faute de frappe
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Retourner à la page d'accueil
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
