// component
import {
  IoHome,
  IoHomeOutline,
  IoLogOut,
  IoLogOutOutline,
  IoPerson,
  IoPersonOutline, IoSettings,
  IoSettingsOutline
} from 'react-icons/io5';
import {useContext} from "react";
import SvgColor from '../../../components/svg-color';
import UserContext from "../../../index";

// ----------------------------------------------------------------------

const icon = (name, extension) => (
  <SvgColor src={`/assets/icons/navbar/${name}.${extension}`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Accueil',
    path: '/app/feed',
    icon: <IoHomeOutline size={"1.5rem"} style={{color: '#3b387b'}} />,
    active: <IoHome size={"1.5rem"} style={{color: '#3b387b'}} />,
    auth: 'ROLE_USER',
  },
  {
    title: 'Paramètres',
    path: '/app/settings',
    icon: <IoSettingsOutline size={"1.5rem"} style={{color: '#3b387b'}} />,
    active: <IoSettings size={"1.5rem"} style={{color: '#3b387b'}} />,
    auth: 'ROLE_USER',
  },
  {
    title: "Déconnexion",
    path: '/logout',
    icon: <IoLogOutOutline size={"1.5rem"} style={{color: '#3b387b'}} />,
    auth: 'ROLE_USER',
  }
];

export default navConfig;
