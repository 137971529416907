import PropTypes from 'prop-types';
import {NavLink as RouterLink, useNavigate} from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { useContext } from 'react';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import UserContext from '../../index';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const { user } = useContext(UserContext);

  return (
    <Box {...other} sx={{zIndex: 1700}}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map(
          (item) => (item.auth === user.role || user.role === 'ROLE_ADMIN') && <NavItem key={item.title} item={item} />
        )}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, active } = item;
  const {logout, user} = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <StyledNavItem
      component={path === "/logout" ? "a" : RouterLink}
      to={path === "/logout" ? "" : path === "/app/profile/" ? `/app/profile/${user.uuid}` : path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
      onClick={() => {
          if(path === "/logout") {
              logout();
              navigate('/login', { replace: true });
          }
      }}
    >
        {/* eslint-disable-next-line no-restricted-globals */}
      <StyledNavItemIcon>{location.pathname === path ? active : icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
