import ReactDOM from 'react-dom/client';
import {createContext, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';

//
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import {Preferences} from "@capacitor/preferences";
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./components/scroll-to-top";

// ----------------------------------------------------------------------


const root = ReactDOM.createRoot(document.getElementById('root'));

const data = await Preferences.get({key: 'user'});

if (data.value === null) {
    await Preferences.set({
        key: 'user',
        value: JSON.stringify({
            email: '',
            auth: false,
            token: '',
            firstName: '',
            lastName: '',
            role: '',
            uuid: '',
            profilePictureUrl: '',
            swippedOnce: false,
            tokenExpiration: '',
            showNsfw: false,
            blurNsfw: true,
        })
    });
}

const userData = await Preferences.get({key: 'user'});

const UserContext = createContext(userData.value);

const UserProvider = ({children}) => {
    const baseURL = 'https://weazper.com';
    const baseAPI = `${baseURL}/api`;
    
    const [user, setUser] = useState(JSON.parse(userData.value));
    const [appName, setAppName] = useState('Weazper');
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [swippedOnce, setSwippedOnce] = useState(false);
    
    const getAppName = () => {
        axios
        .get(`${baseAPI}/public/name`)
        .then((response) => {
            setAppName(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    const checkTokenExpiration = () => {
        if (user.tokenExpiration !== '') {
            const now = Math.floor(Date.now() / 1000);
            
            if (user.tokenExpiration < now) {
                clearUser();
            }
        }
    }
    
    useEffect(() => {
        getAppName();
        
        const interval = setInterval(() => {
            checkTokenExpiration();
        }, 10000);
        
        return () => clearInterval(interval);
        
    }, []);
    
    const clearUser = async () => {
        await Preferences.set({
            key: 'user',
            value: JSON.stringify({
                email: '',
                username: '',
                auth: false,
                token: '',
                firstName: '',
                lastName: '',
                role: '',
                uuid: '',
                profilePictureUrl: '',
                swippedOnce: false,
                tokenExpiration: '',
                showNsfw: false,
                blurNsfw: true,
            })
        });
        
        setUser({
            email: '',
            password: '',
            username: '',
            auth: false,
            firstName: '',
            lastName: '',
            role: '',
            uuid: '',
            profilePictureUrl: '',
            swippedOnce: false,
            tokenExpiration: '',
            showNsfw: false,
            blurNsfw: true,
        });
    };
    
    const login = (email, pass) => {
        const json = JSON.stringify({email, password: pass});
        
        return axios
        .post(`${baseAPI}/auth/login`, json, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(async (response) => {
            setUser({
                ...user,
                email,
                auth: true,
                username: response.data.username,
                token: response.data.token,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                role: response.data.role,
                uuid: response.data.uuid,
                profilePictureUrl: response.data.profilePictureUrl ? response.data.profilePictureUrl : '',
                swippedOnce: false,
                tokenExpiration: jwtDecode(response.data.token).exp,
                showNsfw: response.data.showNsfw,
            });
            await Preferences.set({
                key: 'user',
                value: JSON.stringify({
                    email,
                    auth: true,
                    username: response.data.username,
                    token: response.data.token,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    role: response.data.role,
                    uuid: response.data.uuid,
                    profilePictureUrl: response.data.profilePictureUrl ? response.data.profilePictureUrl : '',
                    swippedOnce: false,
                    tokenExpiration: jwtDecode(response.data.token).exp,
                    showNsfw: response.data.showNsfw,
                })
            });
            
            return true;
        })
        .catch(() => false);
    };
    
    // Logout updates the user data to default
    const logout = () => {
        clearUser();
    };
    
    
    return (
        <UserContext.Provider
            value={{
                user,
                login,
                logout,
                baseAPI,
                baseURL,
                appName,
                getAppName,
                toggleSwitch,
                setToggleSwitch,
                swippedOnce,
                setSwippedOnce
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

root.render(
    <HelmetProvider>
        <UserProvider>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/*" element={<App/>}>
                        {' '}
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserProvider>
    </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export default UserContext;
