import {Button, buttonClasses, Stack, Typography} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import Iconify from "../../components/iconify";


const StyledAppStoreButton = styled(Button)(({ theme }) => ({
    flexShrink: 0,
    padding: '5px 12px',
    color: theme.palette.common.white,
    border: `solid 1px ${alpha(theme.palette.common.black, 0.24)}`,
    background: `linear-gradient(180deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
    [`& .${buttonClasses.startIcon}`]: {
        marginLeft: 0,
    },
}));


export function AppStoreButton({ ...other }) {
    return (
        <Stack direction="row" flexWrap="wrap" spacing={2} {...other}>
            <StyledAppStoreButton startIcon={<Iconify icon="ri:apple-fill" width={28} />}>
                <Stack alignItems="flex-start">
                    <Typography variant="caption" sx={{ opacity: 0.72 }}>
                        Télécharger sur
                    </Typography>
                    
                    <Typography variant="h6" sx={{ mt: -0.5 }}>
                        Apple Store
                    </Typography>
                </Stack>
            </StyledAppStoreButton>
            
            <StyledAppStoreButton startIcon={<Iconify icon="logos:google-play-icon" width={28} />}>
                <Stack alignItems="flex-start">
                    <Typography variant="caption" sx={{ opacity: 0.72 }}>
                        Télécharger sur
                    </Typography>
                    
                    <Typography variant="h6" sx={{ mt: -0.5 }}>
                        Google Play
                    </Typography>
                </Stack>
            </StyledAppStoreButton>
        </Stack>
    );
}