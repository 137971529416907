import { Helmet } from 'react-helmet-async';

// @mui
import {CircularProgress, Grid} from '@mui/material';

import dayjs from 'dayjs';
import 'dayjs/locale/fr-ch';
import {useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import { AnimatePresence } from 'framer-motion';
import {Toast} from "@capacitor/toast";
import UserContext from '../../index';

import '../../WeazperCard.css';
import { ModalContext } from '../../ModalContext';
import {colorsPalette} from "../../utils/Utils";
import {AppStoreButton} from "./AppStoreButton";


// ----------------------------------------------------------------------


dayjs.locale('fr-ch');

const createPostData = (uuid, title, content, username, userUuid, mediaUrl, thumbnailUrl, type, randomColor, profilePic, nsfw, likes, dislikes, cover) => ({
    uuid,
    title,
    content,
    username,
    userUuid,
    mediaUrl,
    thumbnailUrl,
    type,
    randomColor,
    profilePic,
    nsfw,
    likes,
    dislikes,
    cover,
});


export default function Home() {
    const { appName } = useContext(UserContext);
    
    
    const componentRef = useRef();

    

    return (
        <>
            <Helmet>
                <title> Dashboard | {appName} </title>
            </Helmet>

            <Grid
                container
                sx={{ height: '100%', display: "flex"}}
                ref={componentRef}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                    <AppStoreButton sx={{justifyContent: "center"}} />
            </Grid>
        </>
    );
}
