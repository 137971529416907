import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Box,
    Button, Checkbox,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel,
    FormGroup, Grid, IconButton, InputAdornment, Link, Snackbar, Stack, Step, StepLabel, Stepper, TextField,
    Typography
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { AvatarCreator, AvatarCreatorConfig, AvatarExportedEvent } from '@readyplayerme/react-avatar-creator';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr-ch';
import {styled} from "@mui/material/styles";
import PropTypes from "prop-types";
import {Check} from "@mui/icons-material";
import dayjs from "dayjs";
import axios from "axios";
import {Alert} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import Markdown from 'react-markdown';
import {Device} from "@capacitor/device";
import {Toast} from "@capacitor/toast";
import UserContext from "../../index";
import useResponsive from "../../hooks/useResponsive";
import Iconify from "../../components/iconify";
import cgu from "./cgu";
import privacy from "./privacy";


const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

const steps = ['Identifiants', 'Verification', 'Confirmation'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    
    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };
    
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const Deletion = () => {
    const { baseAPI, appName, login, user, logout } = useContext(UserContext);
    const navigate = useNavigate();
    const mdUp = useResponsive('up', 'md');
    
    const [activeStep, setActiveStep] = useState(0);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    
    const [codeSent, setCodeSent] = useState(false);
    const [codeSentOnce, setCodeSentOnce] = useState(false);
    const [code, setCode] = useState(null);
    const [checked, setChecked] = React.useState(false);
    const [timer, setTimer] = useState(null);
    
    
    const checkLogin = () => {
        if(email === user.email) {
            login(email, password)
            .then((res) => {
                if(res) {
                    console.log(res);
                    const config = {
                        method: 'POST',
                        url: `${baseAPI}/auth/delete`,
                        headers: {
                            "Authorization": `Bearer ${user.token}`
                        },
                    }
                    
                    axios(config)
                    .then((res) => {
                        console.log(res);
                        setActiveStep(activeStep+1);
                        setCodeSentOnce(true)
                        Toast.show({
                            text: 'Un code vous a été envoyé par mail',
                            duration: 'short',
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                } else {
                    Toast.show({
                        text: 'Mauvais identifiants',
                        duration: 'short',
                    })
                }
            })
        } else {
            Toast.show({
                text: 'Mauvais identifiants',
                duration: 'short',
            })
        }
        
    }
    
    const deleteAccount = () => {
        const config = {
            method: 'POST',
            url: `${baseAPI}/auth/delete?code=${code}`,
            headers: {
                "Authorization": `Bearer ${user.token}`
            },
        }
        
        axios(config)
        .then((res) => {
            console.log(res);
            setActiveStep(activeStep+1);
            setCodeSentOnce(true)
            Toast.show({
                text: 'Votre compte a été supprimé avec succès',
                duration: 'short',
            })
        })
        .catch((err) => {
            console.log(err);
        })
    }
    
    
    useEffect(() => {
        if(timer === null) return; // skip if timer is not started
        
        if(timer === 0){
            setCodeSent(false);
        } else {
            const timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
            // eslint-disable-next-line consistent-return
            return () => clearTimeout(timeoutId);
        }
    }, [timer]);
    
    
    // Check if password length is greater than or equal to 8
    const isValidPasswordLength = (password) => password.length >= 8;

    // Check if password contains a number
    const containsNumber = (password) => /\d/.test(password);
    
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    
    return (
        <>
            <Helmet>
                <title> Login | {appName} </title>
            </Helmet>
            
            <StyledRoot>
                {mdUp && (
                    <StyledSection>
                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <img src={`${baseAPI}/public/logo`} width={'300px'} style={{ margin: 'auto' }} alt="login" />
                        </Box>
                    </StyledSection>
                )}
                
                <Container maxWidth="sm">
                    <StyledContent>
                        {!mdUp && (
                            <Box sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: "25px", textAlign: "center" }}>
                                <img src={`${baseAPI}/public/logo`} width={'100px'} style={{ margin: 'auto' }} alt="login" />
                                <Typography variant={"h4"} mt={2}>
                                    Suppression de compte
                                </Typography>
                            </Box>
                        )}
                        
                        <Stack direction={"column"} sx={{width: '100%'}} spacing={4}>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            
                            {activeStep === 0 && (
                                <Grid
                                    container
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Grid item xs={12} mb={0}>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { width: '100%' },
                                            }}
                                            mb={1}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                margin="normal"
                                                onKeyDown={(ev) => {
                                                    if (ev.key === 'Enter') {
                                                        ev.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} mb={3}>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { width: '100%' },
                                            }}
                                            mb={1}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Mot de passe"
                                                value={password}
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(e) => setPassword(e.target.value)}
                                                margin="normal"
                                                onKeyDown={(ev) => {
                                                    if (ev.key === 'Enter') {
                                                        ev.preventDefault();
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} textAlign="left">
                                        <Button variant={"outlined"} sx={{width: "95%"}} onClick={() => {
                                            navigate(-1)
                                        }}>Annuler</Button>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                        {/* what the actual fuck ? */}
                                        <Button disabled={!email || !password} variant={"contained"} sx={{width: "95%"}} onClick={() => checkLogin()}>Suivant</Button>
                                    </Grid>
                                </Grid>
                            )}
                            
                            
                            {activeStep === 1 && (
                                <Grid
                                    container
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Grid item xs={12} mt={1}>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { width: '100%' },
                                            }}
                                            mb={1}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                required
                                                disabled={!codeSentOnce}
                                                id="outlined-required"
                                                label="Code de validation"
                                                type={"number"}
                                                value={code != null ? code : ""}
                                                onChange={(e) => setCode(e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} mb={3} mt={1}>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { width: '100%' },
                                            }}
                                            mb={1}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={checked} onChange={handleChange} />}
                                                    label={
                                                        <Typography variant={"body2"}>
                                                            J'accepte que mon compte ainsi que toutes les données qui y sont associées soit supprimés définitivement
                                                        </Typography>
                                                    }
                                                />
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} textAlign="left">
                                        <Button variant={"outlined"} sx={{width: "95%"}} onClick={() => {
                                            setActiveStep(activeStep - 1)
                                            setCodeSentOnce(false)
                                        }}>Retour</Button>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                        {/* what the actual fuck ? */}
                                        <Button disabled={!code || !checked} variant={"contained"} sx={{width: "95%"}} onClick={() => {
                                            setActiveStep(activeStep + 1)
                                            deleteAccount()
                                        }}>Supprimer mon compte</Button>
                                    </Grid>
                                </Grid>
                            )}
                            
                            {activeStep === 2 && (
                                <Grid
                                    container
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Grid item xs={12} mb={3} />
                                    <Grid item xs={6} textAlign="right">
                                        <Button variant={"contained"} sx={{width: "95%"}} onClick={() => {logout(); navigate("/", {replace: true})}}>Terminer</Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Stack>
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}

export default Deletion;