import {useContext, useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';

import {Capacitor} from "@capacitor/core";
import {Keyboard} from "@capacitor/keyboard";

//
import Header from './header';
import UserContext from '../../index';
import Nav from './nav';
import { ModalContext } from '../../ModalContext';
import BottomNavbar from '../BottomNavbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 10,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { baseAPI, appName, setToggleSwitch, toggleSwitch, keyboard } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const { isModalVisible } = useContext(ModalContext);
  
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} onOpenNav={() => setOpen(true)} />

      <Main sx={{paddingBottom: keyboard ? "10px" : "80px"}}>
        <Outlet />
      </Main>

    </StyledRoot>
  );
}
