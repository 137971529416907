import {Helmet} from 'react-helmet-async';
import React, {forwardRef, useContext, useState} from 'react';

// @mui
import {
    Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack,
    SwipeableDrawer, Switch, Tooltip,
    Typography,
} from '@mui/material';
import {
    IoWifi,
    IoBluetooth,
    IoLanguage,
    IoWarning,
    IoImageOutline,
    IoChevronForward,
    IoRemove,
    IoShieldHalf, IoPerson
} from "react-icons/io5";

import MuiAlert from '@mui/material/Alert';

// mock
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {Toast} from "@capacitor/toast";
import { FaOtter } from "react-icons/fa6";
import {BsPhoneVibrate} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import UserContext from '../../index';

export default function Settings() {
    const {user, baseAPI, modifyDetails, appName, blurNsfw, changeShowNsfw, showNsfw, setBlurNsfw, vibration, setVibration, newPost, setNewPost} = useContext(UserContext);
    const [languageDrawer, setLanguageDrawer] = useState(false);
    const navigate = useNavigate();
    
    const toggleLangageDrawer = () => {
        setLanguageDrawer(!languageDrawer);
    }
    
    const setLocale = (locale) => {
        const formData = new FormData();
        const request = {};
        request.locale = locale;
        formData.append("request", new Blob([JSON.stringify(request)], { type: "application/json" }));
        
        const config = {
            method: 'put',
            url: `${baseAPI}/users/${user.uuid}`,
            headers: {
                "Authorization": `Bearer ${user.token}`,
            },
            data: formData
        }
        
        axios(config)
        .then((response) => {
            setLanguageDrawer(false);
            Toast.show({
                text: "Langue changée avec succès",
                duration: "short"
            })
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    
    return (
        <>
            <Helmet>
                <title> Paramètres | {appName} </title>
            </Helmet>
            
            <Grid
                container
                sx={{width: "100%", margin: "auto"}}
                mt={0}
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} textAlign={"center"} margin={0} padding={0} style={{paddingLeft: "0px"}}>
                    <List
                        sx={{ width: '100%' }}
                        subheader={<Typography variant="subtitle2" sx={{backgroundColor: "#9b9fa2", color: "white", padding: "0.5rem", fontWeight: 500, fontSize: "0.75rem"}}>GESTION DU COMPTE</Typography>}
                    >
                        <ListItem onClick={() => navigate("/deletion")}>
                            <ListItemIcon>
                                <IoPerson size={"1.5rem"} color={"rgb(59, 56, 123)"} />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-vibration" primary="Supprimer le compte" />
                            <IconButton aria-label="switch-list-label-vibration" color="primary" >
                                <IoChevronForward  />
                            </IconButton>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <SwipeableDrawer
                sx={{zIndex: 5000}}
                anchor={"bottom"}
                disableDiscovery
                disableSwipeToOpen
                onOpen={() => {
                }}
                open={languageDrawer}
                onClose={toggleLangageDrawer}
            >
                <div style={{textAlign: "center"}}>
                    <Stack alignItems={"center"} justifyContent={"center"}>
                        <IoRemove size={"2rem"}/>
                    </Stack>
                </div>
                <List>
                    <ListItemButton onClick={() => setLocale("fr_CH")}>
                        <ListItemText primary="Français"/>
                    </ListItemButton>
                    <ListItemButton onClick={() => setLocale("en_US")}>
                        <ListItemText primary="English"/>
                    </ListItemButton>
                </List>
            </SwipeableDrawer>
        </>
    );
}
